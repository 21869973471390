import React, { useEffect, useState } from 'react';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import RestoreIcon from "@material-ui/icons/Restore";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import BookIcon from '@material-ui/icons/Book';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import ContactsIcon from '@material-ui/icons/Contacts';
import EventIcon from '@material-ui/icons/Event';
import axios from 'axios';
import { FaEnvelope, FaFacebook, FaHome, FaPrayingHands, FaWhatsapp, FaYoutube } from 'react-icons/fa';  // Importez l'icône FaPrayingHands
import { FaBook } from 'react-icons/fa';


const CustomBottomNavigation = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [backgroundColor, setBackgroundColor] = useState('#202427'); // Couleur par défaut

  useEffect(() => {
    // Faites la requête à votre API pour récupérer la couleur de fond
    const url = `${process.env.REACT_APP_API_ROOT_V1}/background-color`;
    axios.get(url)
      .then(response => {
        // Mettez à jour la couleur de fond dans l'état
        setBackgroundColor(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de la couleur de fond :", error);
      });
  }, []); // Assurez-vous de passer une dépendance vide pour que cela se produise une seule fois au chargement

  const handleNavigation = (label) => {
    switch (label) {
      case "Accueil":
        navigate("/");
        break;
      case "Youtube":
        //navigate("/articles/202");
        window.open("https://www.youtube.com/@essohlewijeanmarc7832", "_blank");
        break;
      case "Facebook":
        //navigate("/articles/212");
        //navigate("https://www.facebook.com/coachlewi2021/?paipv=0&eav=AfYVHv2iK1oc0QG0GNJ1VXzpPYhHZkIArGdiwhwRSAZfNdsA65iewbCMn7cuKd33NjQ&_rdr");
        window.open("https://www.facebook.com/coachlewi2021/?paipv=0&eav=AfYVHv2iK1oc0QG0GNJ1VXzpPYhHZkIArGdiwhwRSAZfNdsA65iewbCMn7cuKd33NjQ&_rdr", "_blank");
        break;
      case "Whatsapp":
        const whatsappUrl = "https://api.whatsapp.com/send?phone=22565110441&text=" + encodeURIComponent("bonjour COACH LEWI");
        window.location.href = whatsappUrl;
        break;
      case "Calendrier":
        navigate("/calendrier");
        break;
      case "Me Contacter":  // Nouvelle option "Me Contacter"
        navigate("/soumettre-sujet");  // Ajoutez le lien vers la page "Me Contacter"
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(e, newValue) => {
        setValue(newValue);
      }}
      className="fixed bottom-0 w-full p-4"
      style={{ backgroundColor: "#202427 !important", paddingTop: 20, paddingBottom: 30, boxShadow:'rgb(193 193 193) 100px 0px 10px 0px' }}
    >
    <BottomNavigationAction
        style={{ color: 'white' }}
        label="Accueil"
        icon={<FaHome />}
        onClick={() => handleNavigation("Accueil")}
      />
    <BottomNavigationAction
        style={{ color: 'white' }}
        label="Whatsapp"
        icon={<FaWhatsapp />}
        onClick={() => handleNavigation("Whatsapp")}
      />
      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Youtube"
        icon={<FaYoutube />}
        onClick={() => handleNavigation("Youtube")}
      />

{/*
      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Facebook"
        icon={<FaFacebook />}
        onClick={() => handleNavigation("Facebook")}
      />
 */}

      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Contact"
        icon={<FaEnvelope />} 
        onClick={() => handleNavigation("Me Contacter")}
      />
      
{/*
      <BottomNavigationAction
        style={{ color: 'white' }}
        label="Calendrier"
        icon={<EventIcon />}
        onClick={() => handleNavigation("Calendrier")}
      />
 */}

    </BottomNavigation>
  );
}

export default CustomBottomNavigation;
