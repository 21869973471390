import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SliderComponent from "../../SliderComponent";
import Posts from "../Posts";
import PredicationPosts from "../../PostPredication";
import ProjetPosts from "../../../ProjetPosts";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../Footer";
import CustomBottomNavigation from "../../common/BottomNavigationAction";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [latestArticle, setLatestArticle] = useState(null);
  const [continuousInfoArticles, setContinuousInfoArticles] = useState([]);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT}/categories`;
        const response = await axios.get(url);
        const categoriesWithCount = await Promise.all(
          response.data.map(async (category) => {
            const count = await fetchArticleCount(category.id);
            return { ...category, count };
          })
        );
        // Exclude the "Non classé" category and "PROJETS" category
        const filteredCategories = categoriesWithCount.filter(
          (category) =>
            category.name !== "Non classé" && category.name !== "PROJETS"
        );
        setCategories(filteredCategories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchLatestArticle = async () => {
      try {
        //const url = `${process.env.REACT_APP_API_ROOT}/categories`;
        const url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=1&orderby=date&order=desc&categories=213`;
        //const url = "http://localhost/wpheadless/server/wp-json/wp/v2/posts?per_page=1&orderby=date&order=desc&categories=213"; // Changez cette URL si nécessaire
        const response = await axios.get(url);
        if (response.data.length > 0) {
          setLatestArticle(response.data[0]);
        }
        setLoading(false);
        console.log(url);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching latest article:", error);
        setLoading(false);
      }
    };

    fetchLatestArticle();
  }, []);


/*

          {categories.map((category) => (
  <Link to={`/articles/${category.id}`} key={category.id}>

      <Link to={`/articles/${category.id}`}>
      <FontAwesomeIcon icon={faFolder} style={{color:'#FFC107'}} />  
      <span
            className="text-gray-400 px-2 py-1 rounded-full"
            style={{ fontSize: 12 }}
          >
            {category.count} {category.count > 1 ? 'articles':'article'} 
          </span>

        <h2 className="text-lg font-bold mb-" style={{ fontSize: 14 }}>
          
          
          &nbsp;{category.name}

        </h2>
      </Link>

  </Link>
))}
 
*/

  const HorizontalScroll = () => {
    
    const containerStyle = {
      display: 'flex',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      padding: '5px',      
      //borderTop: '7px solid #00a7e3',
      paddingLeft: '0px',
      width: '100%', // Assurez-vous que le conteneur a une largeur définie
      marginTop:'-25px',
    };
  
    const itemStyle = {
      display: 'inline-block',
      padding: '5px 20px',
      marginRight: '10px',      
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '5px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize:12
    };
  
    return (
      <div style={containerStyle}>
        {categories.map((category, index) => (
          <div key={index} style={itemStyle}>
          <Link to={`/articles/${category.id}`}>
            {category.name}
          </Link>
          </div>
        ))}
      </div>
    );
  };
  

  const fetchArticleCount = async (categoryId) => {
    try {
      const url = `${process.env.REACT_APP_API_ROOT}/posts?categories=${categoryId}`;
      const response = await axios.get(url);
      return response.data.length;
    } catch (error) {
      console.error(
        `Error fetching article count for category ${categoryId}:`,
        error
      );
      return 0;
    }
  };

  useEffect(() => {
    const fetchContinuousInfoArticles = async () => {
      try {
        const url = `${process.env.REACT_APP_API_ROOT}/posts?per_page=6&orderby=date&order=desc&categories=215`;
        //const url = 'http://localhost/wpheadless/server/wp-json/wp/v2/posts?per_page=4&orderby=date&order=desc&categories=215';
        const response = await axios.get(url);
        if (response.data.length > 0) {
          setContinuousInfoArticles(response.data);
        }
      } catch (error) {
        console.error("Error fetching continuous info articles:", error);
      }
    };

    fetchContinuousInfoArticles();
  }, []);

  return (
    <>
      <div
        className="lg:px-[20px] card shadow-lg rounded-lg flex flex-col gap-5 p-5 bg-white mt-0 container mx-auto my-auto p-1"        
      >
      {/**sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-12 */}
        <div className="grid grid-cols-12 gap-4 h-full desktop-space2">                
          <div className="col-span-8 p-0 pt-4">
          <HorizontalScroll/>
          <br/>          
          {loading ? (
              <div className="col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin mb"/>
            ) : latestArticle ? (
              <>
              
                <img
                  src={latestArticle.featured_src}
                  alt={latestArticle.title.rendered}
                  className="w-full h-auto mb-4"
                />
                <Link                
                  to={`/${latestArticle.slug}`}
                  className="hover:underline"
                  style={{color:''}}
                >

<span style={{ 
        fontSize: 12, 
        backgroundColor: '#03a7e3', 
        color: 'white', 
        fontWeight: '500', 
        width: 'auto', 
        padding: '5px 5px',        
      }}>
        A LA UNE
      </span>

                <h1 className="text-xl font-bold mb-2" style={{fontSize:32, lineHeight:1.4, marginTop:10}}>
                  {latestArticle.title.rendered}
                </h1>
                </Link>
                <p className="text-gray-600 mb-4">
                  publié le {new Date(latestArticle.date).toLocaleDateString()}
                </p>
                {/**<Link to={`/posts/${post.id}`} onClick={() => incrementArticleVisits(post.id)}></Link> */}
                
              </>
            ) : (
              <p>Aucun article à la une pour le moment</p>
            )}
          
<hr/>
            
          </div>

          <div className="col-span-4 p-5" style={{background:'#f0f0f0', height:'auto'}}>            
            <div style={{background:'black', padding:10, color:'white', fontSize:20, textAlign:'center', fontWeight:'500'}}>L'INFO EN CONTINU</div>
            <br/>
             {/* Contenu de la deuxième colonne */}
             
            {continuousInfoArticles.length > 0 ? (
              continuousInfoArticles.map((article) => (
                <div key={article.id} className="mb-0 flex items-center">
                  <img src={article.featured_src} alt={article.title.rendered} className="w-20 h-16 mr-4" style={{marginTop:'-27px'}}/>
                  <div>
                  <Link to={`/${article.slug}`} className=" hover:underline">
                  <h2 className="text-lg font-bold mb-1" style={{fontSize:16}}>
            {article.title.rendered.length > 40 
              ? `${article.title.rendered.slice(0, 40)}...` 
              : article.title.rendered} 
          </h2><p className="text-gray-600 text-sm mb-1" style={{fontSize:11, marginTop:-5}}>{new Date(article.date).toLocaleDateString()}</p>
          <br/>
                  </Link>
                    

                  </div>
                </div>
              ))
            ) : (
              <p>Aucun article pour l'instant</p>
            )}
          </div>
        </div>


        <Posts /> 

      </div>

      

      <div className="">
        {/**<SliderComponent /> **/}
      

        {loading ? (
          <div className="col-span-3 w-4 h-4 absolute left-1/2 top-1/2 rounded-full border-4 border-b-0 border-blue-500 animate-spin" />
        ) : categories.length === 0 ? (
          <p>Aucune catégorie pour le moment</p>
        ) : (
          <>
            <div className="container mx-auto my-auto mb-0 p-1">

              {/**<h1 className='lg:w-5/6 xl:w-4/5 2xl:w-3/4 mx-6 py-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 text-2xl font-bold' style={{ fontSize: 20 }}>CATEGORIES</h1> */}

              <div
                className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-5"
                style={{ marginTop: 10 }}
              >
              
                {/*
{categories.map((category) => (
  <Link to={`/articles/${category.id}`} key={category.id}>
    <div className="p-4 bg-white shadow-md rounded-md" style={{}}>
      <Link to={`/articles/${category.id}`}>
      <FontAwesomeIcon icon={faFolder} style={{color:'#FFC107'}} />  
      <span
            className="text-gray-400 px-2 py-1 rounded-full"
            style={{ fontSize: 12 }}
          >
            {category.count} {category.count > 1 ? 'articles':'article'} 
          </span>
      <br/>
        <h2 className="text-lg font-bold mb-" style={{ fontSize: 14 }}>
          
          
          &nbsp;{category.name}

        </h2>
      </Link>
    </div>
  </Link>
))}
 */}
              </div>
            </div>
            
            {/* <Posts /> */}

            {/*
          <PredicationPosts />
          <ProjetPosts />          
          
           */}

            <CustomBottomNavigation />
            {/**<Footer/> */}
          </>
        )}
      </div>
    </>
  );
};

export default Home;
