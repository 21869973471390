import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaInfoCircle,FaCalendarAlt, FaProjectDiagra, FaImages, FaCalendar, FaAddressBook, FaUserMd, FaFilePdf, FaPrayingHands, FaChurch, FaLaptopCode, FaCode, FaHome, FaHouseUser, FaPlayCircle } from 'react-icons/fa';
import ContactsIcon from '@material-ui/icons/Contacts';  // Importez l'icône de Material-UI
import HomeIcon from '@material-ui/icons/Home';  // Importez l'icône de Material-UI
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LiveTvIcon from '@material-ui/icons/LiveTv';
//https://v4.mui.com/components/material-icons/

import axios from "axios";
//import { FaInfo, FaProjectDiagram, FaCalendar, FaFilePdf } from 'react-icons/fa';
//import { MdContacts, MdLocalHospital } from 'react-icons/md';


const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('#2196f3'); // Couleur par défaut #111827

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  const HorizontalScroll = () => {
    const countries = [
      "France",
      "Europe",
      "Afrique",
      "Amériques",
      "Asie-Pacifique",
      "Moyen-Orient",
      "France",
      "Europe",
      "Afrique",
      "Amériques",
      "Asie-Pacifique",
      "Moyen-Orient"
    ];
  
    const containerStyle = {
      display: 'flex',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      padding: '5px',      
      borderTop: '7px solid #00a7e3',
      //paddingLeft: '20px',
      width: '100%', // Assurez-vous que le conteneur a une largeur définie
    };
  
    const itemStyle = {
      display: 'inline-block',
      padding: '5px 20px',
      marginRight: '10px',      
      //backgroundColor: '#f0f0f0',
      //border: '1px solid #ccc',
      borderRadius: '5px',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    };
  
    return (
      <div style={containerStyle}>
        {countries.map((country, index) => (
          <div key={index} style={itemStyle}>
            {country}
          </div>
        ))}
      </div>
    );
  };
  
  
  useEffect(() => {
    // Faites la requête à votre API pour récupérer la couleur de fond
    const url = `${process.env.REACT_APP_API_ROOT_V1}/background-color`;
    axios.get(url)
      .then(response => {
        // Mettez à jour la couleur de fond dans l'état
        setBackgroundColor(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération de la couleur de fond :", error);
      });
  }, []); // Assurez-vous de passer une dépendance vide pour que cela se produise une seule fois au chargement

  return (
    <>
      <nav className={`dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b shadow-lg`} style={{ background: backgroundColor }}>

        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="">
            <img src="../../../../logo-france-24.png" alt="Logo AD-Excellence.net" style={{width:"45%"}}/>             
          </Link>          
          <button
            onClick={toggleMenu}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-multi-level"
            aria-expanded={isMenuOpen}
            style={{ backgroundColor: '#f3f4f6' }}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className={`w-full md:flex md:w-auto ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-multi-level">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4  rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900"
              style={{ background: backgroundColor}}>
              <li>
                <Link
                  to="/presentation/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: '#202427', textAlign:'center' }}
                >
                <HomeIcon/><br/>A la une
                   
                </Link>
              </li>
              

              <li>
                <Link
                  to="/presentation/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: '#202427', textAlign:'center' }}
                >
                <PlayCircleOutlineIcon/><br/>Replay
                   
                </Link>
              </li>

              <li>
                <Link
                  to="/presentation/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: '#202427', textAlign:'center' }}
                >
                <LibraryBooksIcon/><br/>Thématique                   
                </Link>
              </li>

              <li>
                <Link
                  to="/presentation/"
                  className="block mt-4 lg:inline-block lg:mt-0"
                  style={{ fontSize: 15, color: '#202427', textAlign:'center' }}
                >
                <LiveTvIcon/><br/>🔴 Direct                   
                </Link>
              </li>              

            </ul>            
          </div>
        </div>
        <br/>
      <HorizontalScroll/>
      </nav>      
      <div className="desktop-space">
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className="mobile-space">
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
        <center>
          <img src="../../../../5XLVP3AJ3RBDZNF5LNERFW.png" className="hidden lg:block" alt="Logo AD-Excellence.net" style={{width:"45%", marginTop:"-2%", marginBottom:"4%"}}/>             
        </center>
    </>
  );
};

export default Navbar;
